const about = `My name is Bikila Ketema. I recently graduated from Addis Ababa University with a degree in Business Administration and Information Systems. I also completed a 12-month software engineering program at ALX Africa (Holberton School), where I acquired skills in C programming, Linux CMD, server configuration, and GitHub. My education has provided me with a solid foundation in both software engineering and essential business management areas, including financial, organizational, project, and human resources management.

In addition to my academic background, I’ve developed a strong proficiency in graphic design. Over time, I’ve designed logos, banners, posters, and other visual materials, turning a hobby into a professional skill set.

I am available for web development and graphic design projects and am also open to full-time positions in IT roles. My combined expertise in business administration and software engineering positions me well to contribute effectively to your team.`;

const description = `I am a full stack software developer with a focus on backend development. My expertise extends beyond technical skills; I also possess strong business acumen from studying Business Administration and Information Systems. This combination allows me to quickly grasp business requirements and effectively address business challenges using my software development skills.`;

const position = `Fullstack software developer | Graphic designer`;

export { position, about, description };
