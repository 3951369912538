import { React, useState } from "react";
import projects from "../constants/ProjectsList";
import { LinkIc, Github } from "../assets/icons";
import ProjectModal from "./projectModal";

function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <section className="p-4 md:p-8 bg-primary pt-24 md:pt-24">
      <h1 className="text-2xl md:text-3xl text-white font-bold text-center mb-6 md:mb-8">
        Projects
      </h1>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {projects.map((project) => (
          <div
            key={project.id}
            className="border p-4 rounded-lg shadow-md bg-primary text-white flex flex-col gap-4 hover:cursor-pointer hover:bg-secondary"
            onClick={() => openModal(project)}
          >
            <div className="flex flex-row justify-between mb-4">
              {/* Render GitHub icon only if project.github is not an empty string */}
              {project.github && (
                <a href={project.github} target="_blank" rel="noreferrer">
                  <img src={Github} alt="GitHub icon" className="w-8 md:w-10" />
                </a>
              )}

              {/* Render Link icon only if project.link is not an empty string */}
              {project.link && (
                <a href={project.link} target="_blank" rel="noreferrer">
                  <img src={LinkIc} alt="Link icon" className="w-8 md:w-10" />
                </a>
              )}
            </div>

            <h2 className="text-xl md:text-lg font-bold mb-4">
              {project.title}
            </h2>
            <p className="text-gray-300 text-sm mb-4">{project.description}</p>
            <p className="text-sm text-gray-400 mb-2">
              <strong>Languages:</strong> {project.languages.join(", ")}
            </p>
            <p className="text-sm text-gray-400">
              <strong>Frameworks:</strong> {project.frameworks.join(", ")}
            </p>
          </div>
        ))}
      </div>
      {selectedProject && (
        <ProjectModal
          isOpen={isModalOpen}
          onClose={closeModal}
          project={selectedProject}
        />
      )}
    </section>
  );
}

export default Projects;
