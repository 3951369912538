import React from "react";
import { FaTimes } from "react-icons/fa";
import Resume from "../assets/docs/Bikila_Ketema_Resume.pdf";

const ResumeModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-[90%] h-[90%]">
        {/* Close button */}
        <button
          className="absolute top-8 right-4 text-white text-2xl bg-blue text-white p-2 rounded-lg"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        {/* Resume in an iframe */}
        <iframe
          src={Resume}
          className="w-full h-full"
          frameBorder="0"
          title="Resume"
        ></iframe>
      </div>
    </div>
  );
};

export default ResumeModal;
