import React from "react";
import Contact from "./contact";
import About from "./about";
import Hero from "./hero";
import Skills from "./skills";
import Education from "./education";

// Create a functional component
function Home() {
  // Return JSX
  return (
    <div className="bg-primary">
      <Hero />
      <About />
      <Education />
      <Skills />
      <Contact />
    </div>
  );
}

// Export the component
export default Home;
