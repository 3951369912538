import {
  spvscreenshot1,
  spvscreenshot2,
  spvscreenshot3,
  spvscreenshot4,
  spvscreenshot5,
} from "../assets/screenshots/thesparkvalley";

import {
  egscreenshot1,
  egscreenshot2,
  egscreenshot3,
  egscreenshot4,
  egscreenshot5,
  egscreenshot6,
  egscreenshot7,
} from "../assets/screenshots/e-gebeya";

import {
  bbscreenshot1,
  bbscreenshot2,
  bbscreenshot3,
  bbscreenshot4,
} from "../assets/screenshots/bookstore";

const projects = [
  {
    id: 1,
    title: "E-gebeya an online Marketplace",
    description:
      "Developed an online marketplace with tremendous features for graduation of my BAIS bachelor of arts degree. ",
    longDescription: `For my Bachelor’s degree graduation project, I developed an online e-commerce website. Initially, I created it as part of the ALX Africa full-stack software engineering program using Python (Flask), HTML, CSS, and SQLite. The website included features such as product listing, purchasing, and user authentication. In the first version, I utilized Firebase for authentication. However, for my senior project, I refactored it by incorporating Flask's authentication system and enhancing the frontend with Tailwind CSS. This upgrade significantly improved the website's functionality and appearance.

The first version of the project was deployed on a free server provided by ALX Africa, along with a free dot tech domain name. This project was my first real-world application, where I gained valuable skills in project management, programming, and server configuration.

Fun fact: This was my first programming project through out my software development journey.`,
    link: "https://e-gebeya-com.onrender.com/",
    github: "https://github.com/Bikilaketema/E-Gebeya.com",
    screenshots: [
      egscreenshot1,
      egscreenshot2,
      egscreenshot3,
      egscreenshot4,
      egscreenshot5,
      egscreenshot6,
      egscreenshot7,
    ],
    languages: ["Python", "HTML", "CSS"],
    frameworks: ["Flask", "Tailwind CSS"],
  },
  {
    id: 2,
    title: "Refactored and added a personality test feature | the spark valley",
    description:
      "We worked on the refactoring of the website and also added a Myers Briggs 16 personality types test to their website with two of my collegues.",
    longDescription: `In our project team of three, we refactored a PHP-based website without a backend server into a React app with a Node.js server and a MySQL database. Although our primary objective was to implement the Myers-Briggs personality test feature, which we successfully achieved, we undertook additional tasks. I was responsible for the backend development, where I created a RESTful API in Node.js with full CRUD capabilities.

My role extended to the frontend, where I collaborated with my colleagues and contributed to designing various parts of the web page. After completing both backend and frontend tasks, I was assigned to develop an admin panel. I embraced this task and successfully developed and implemented the admin panel using React, connecting it to the backend server.

Additionally, I integrated the frontend with the backend, ensuring seamless functionality. Finally, I handled the deployment of the website to the server. My hard work was recognized by the Spark Valley, which awarded me a recommendation letter. You can find the recommendation letter on my LinkedIn profile.`,
    link: "https://thesparkvalley.et",
    github: "",
    screenshots: [
      spvscreenshot1,
      spvscreenshot2,
      spvscreenshot3,
      spvscreenshot4,
      spvscreenshot5,
    ],
    languages: ["JavaScript"],
    frameworks: ["React", "Node JS"],
  },
  {
    id: 3,
    title: "Online Bookstore",
    description:
      "Developed an online bookstore with one of my peers at ALX Africa as a requirement for graduation.",
    longDescription: `BB Bookstore | Online bookstore During my ALX Africa software engineering program, a peer and I developed an online bookstore using Node.js for the backend, React for the frontend, and MongoDB for the database. We built the website by learning the MERN stack in just two weeks, despite having no prior experience with this technology stack. This achievement demonstrated my ability to quickly learn and adapt to new tech stacks due to my strong understanding of programming fundamentals.

The project served as our final graduation project from the ALX Africa software engineering program. It taught me valuable lessons in teamwork and collaboration, including using GitHub effectively for project collaboration.

Fun fact: This was my second programming project through out my software development journey.`,
    link: "",
    github: "https://github.com/Bikilaketema/Bookstore",
    screenshots: [bbscreenshot1, bbscreenshot2, bbscreenshot3, bbscreenshot4],
    languages: ["Javascript", "Mongo DB"],
    frameworks: ["React", "Node JS"],
  },
  // Add more projects as needed
];

export default projects;
