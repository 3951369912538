import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config/config";

const BlogHomePage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const handlePostClick = (postId) => {
    navigate(`post/${postId}`);
  };
  

  useEffect(() => {
    axios
      .get(`${config.API_URL}/posts`)
      .then((response) => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setPosts([]);
        if (error.response) {
          console.error("Response error:", error.response.data);
        } else if (error.request) {
          console.error("Request error:", error.request);
        } else {
          console.error("Error", error.message);
        }
        console.error("Config:", error.config);
      });
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen text-4xl bg-primary text-white text-center flex justify-center items-center">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="bg-primary min-h-screen w-full">
      <main>
        <div className="p-4 sm:p-8">
          <div className="flex flex-col gap-4 items-center justify-center mt-20 lg:mt-16 ">
            <h1 className="text-2xl sm:text-3xl text-white">Posts</h1>
          </div>

          <div className="grid grid-cols-1 gap-6 m-6">
            {posts.map((post) => (
              <div
                key={post.id}
                className="flex flex-col lg:flex-row gap-4 border-2 border-white rounded-lg p-4 text-white items-center cursor-pointer hover:bg-secondary"
                onClick={() => handlePostClick(post.id)}
              >
                <img
                  src={post.banner}
                  className="w-full lg:w-[40%] h-40 lg:h-60 rounded-lg object-cover cursor-pointer"
                  alt={post.title}
                />
                <div className="gap-4 w-full lg:w-[60%] flex flex-col justify-between">
                  <h1 className="text-white font-bold text-lg sm:text-xl">
                    {post.title}
                  </h1>
                  <div
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html:
                        post.content.length > 100
                          ? post.content.slice(0, 100) + "..."
                          : post.content,
                    }}
                  ></div>
                  <h1 className="text-white font-bold text-sm sm:text-base">
                    {post.reading_time} minutes reading
                  </h1>
                  <h1 className="text-white font-bold text-sm sm:text-base">
                    Posted on: {new Date(post.date_posted).toLocaleDateString()}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default BlogHomePage;
