import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link for routing
import ResumeModal from "./resumeModal"; // Import the ResumeModal component
import { BK_LOGO } from "../assets/icons";

function Header() {
  // State to handle mobile menu visibility
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // State to handle resume modal visibility
  const [isResumeModalOpen, setResumeModalOpen] = useState(false);

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Toggle resume modal visibility
  const toggleResumeModal = () => {
    setResumeModalOpen(!isResumeModalOpen);
  };

  return (
    <div className="h-20 flex items-center space-between bg-secondary fixed top-0 left-0 w-full px-4 md:px-8 z-50">
      <div className="flex-1 sm:w-[80%]">
        <img src={BK_LOGO} alt="Bikila Ketema logo" className="w-44" />
      </div>
      {/* Desktop Menu */}
      <div className="hidden md:flex justify-end items-center gap-4 md:gap-8 flex-1">
        <Link to="/" className="text-white hover:text-gray">
          HOME
        </Link>
        <Link to="/projects" className="text-white hover:text-gray">
          PROJECTS
        </Link>
        <a
          href="https://www.canva.com/design/DAGSoURJZ-k/fn7RnpwLacuehMXBpdFZDg/view?utm_content=DAGSoURJZ-k&utm_campaign=designshare&utm_medium=link&utm_source=editor"
          className="text-white hover:text-gray py-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          GRAPHICS PORTFOLIO
        </a>
        <Link to="/blog" className="text-white hover:text-gray">
          BLOG
        </Link>

        <button
          className="flex items-center justify-center text-white bg-blue font-bold hover:bg-darkblue rounded-lg h-10 w-20"
          onClick={toggleResumeModal}
        >
          RESUME
        </button>
      </div>
      {/* Mobile Menu Button */}
      <div className="flex md:hidden items-center w-[15%]">
        <button
          className="text-white hover:text-gray relative"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? (
            <FaTimes className="text-2xl transition-transform duration-300 transform rotate-90" />
          ) : (
            <FaBars className="text-2xl transition-transform duration-300 transform rotate-0" />
          )}
        </button>
      </div>
      {/* Mobile Menu Dropdown */}
      {isMobileMenuOpen && (
        <div className="absolute top-20 left-0 w-full bg-secondary flex flex-col items-center lg:hidden">
          <Link
            to="/"
            className="text-white hover:text-gray py-2"
            onClick={toggleMobileMenu}
          >
            HOME
          </Link>
          <Link
            to="/projects"
            className="text-white hover:text-gray py-2"
            onClick={toggleMobileMenu}
          >
            PROJECTS
          </Link>
          <a
            href="https://www.canva.com/design/DAGSoURJZ-k/fn7RnpwLacuehMXBpdFZDg/view?utm_content=DAGSoURJZ-k&utm_campaign=designshare&utm_medium=link&utm_source=editor"
            className="text-white hover:text-gray py-2"
            onClick={toggleMobileMenu}
            target="_blank"
            rel="noopener noreferrer"
          >
            GRAPHICS PORTFOLIO
          </a>
          <Link to="/blog" className="text-white hover:text-gray">
            BLOG
          </Link>

          <button
            className="flex items-center justify-center text-white bg-blue font-bold hover:bg-darkblue rounded-lg h-10 w-24 my-2"
            onClick={toggleResumeModal}
          >
            RESUME
          </button>
        </div>
      )}

      {/* Use the ResumeModal component */}
      <ResumeModal isOpen={isResumeModalOpen} onClose={toggleResumeModal} />
    </div>
  );
}

export default Header;
