import React from "react";
import BikilaKetema from "../assets/images";
import { about } from "../constants/texts";
import { NodeJs, ReactIc, CSS, HTML, Laravel, Python } from "../assets/icons";

// Create a functional component
function About() {
  // Return JSX
  return (
    <section className="App bg-primary h-full flex flex-col md:flex-row px-4">
      {/* Text and Skills Section */}
      <div className="w-full md:w-2/3 flex flex-col text-left p-4 md:p-16 gap-6">
        <p className="text-white text-2xl md:text-3xl">About me</p>
        <p
          className="text-white text-base md:text-lg"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {about}
        </p>

        <p className="text-blue text-lg">
          Here are the programming languages and frameworks that I use:
        </p>
        <div className="flex flex-wrap gap-4 justify-center md:justify-start">
          <img src={NodeJs} alt="Node js icon" className="w-10 md:w-12" />
          <img src={ReactIc} alt="React icon" className="w-10 md:w-12" />
          <img src={CSS} alt="CSS icon" className="w-10 md:w-12" />
          <img src={HTML} alt="HTML icon" className="w-10 md:w-12" />
          <img src={Laravel} alt="Laravel icon" className="w-10 md:w-12" />
          <img src={Python} alt="Python icon" className="w-10 md:w-12" />
        </div>
      </div>
      {/* Image Section */}
      <div className="relative w-full md:w-1/3 flex justify-center items-center mt-8 md:mt-0 lg:pr-10">
        <img
          src={BikilaKetema}
          alt="Bikila Ketema portrait"
          className="w-3/4 md:w-full rounded-lg object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-85"></div>
      </div>
    </section>
  );
}

// Export the component
export default About;
