// components/ProjectModal.js
import { React, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Github, LinkIc } from "../assets/icons";

const ProjectModal = ({ isOpen, onClose, project }) => {
  const modalRef = useRef(null);

  // Prevent page scrolling when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Close the modal when clicking outside of the modal content
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-primary bg-opacity-80 z-50">
      <div
        className="bg-secondary text-white border border-white p-6 rounded-lg w-[90%] max-w-screen-lg max-h-[80vh] overflow-y-auto relative"
        ref={modalRef}
      >
        <button
          className="absolute top-4 right-4 text-white text-xl bg-darkblue p-2 rounded-lg"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <h2 className="text-xl font-bold mb-4">{project.title}</h2>
        <p className="text-gray-700 mb-4" style={{ whiteSpace: "pre-wrap" }}>
          {project.longDescription}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>Languages:</strong> {project.languages.join(", ")}
        </p>
        <p className="text-gray-600 mb-4">
          <strong>Frameworks:</strong> {project.frameworks.join(", ")}
        </p>

        <div className="flex gap-8">
          {project.github && (
            <a
              href={project.github}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              <img src={Github} alt="GitHub icon" className="w-8 md:w-10" />
            </a>
          )}
          {project.link && (
            <a
              href={project.link}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              <img src={LinkIc} alt="Link icon" className="w-8 md:w-10" />
            </a>
          )}
        </div>

        {/* Displaying screenshots */}
        <div className="flex flex-col gap-4 mt-4">
          <p className="text-2xl font-bold text-center">Screenshots</p>
          {project.screenshots &&
            project.screenshots.map((screenshot, index) => (
              <img
                key={index}
                src={screenshot}
                alt={`Screenshot ${index + 1} of ${project.title}`}
                className="w-full rounded-lg shadow-lg"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
