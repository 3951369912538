import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config/config";
import { CategoryIcon, TimeIcon } from "../assets/icons";

const SinglePost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [styledContent, setStyledContent] = useState("");

  useEffect(() => {
    axios
      .get(`${config.API_URL}/posts/${id}`)
      .then((response) => {
        const postData = response.data;

        // Modify the raw HTML content to include Tailwind classes
        const styledHtml = styleContent(postData.content);

        setPost(postData);
        setStyledContent(styledHtml);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  // Function to style HTML content with Tailwind classes
  const styleContent = (html) => {
    const styledHtml = html
      .replace(
        /<h1>(.*?)<\/h1>/g,
        '<h1 class="text-2xl text-white font-bold mb-4">$1</h1>'
      )
      .replace(
        /<h2>(.*?)<\/h2>/g,
        '<h2 class="font-bold text-white text-2xl mb-6 mt-4 border-b-2 border-blue-800 pb-2">$1</h2>'
      )
      .replace(/<p>(.*?)<\/p>/g, '<p class="mb-4">$1</p>')
      .replace(/<ul>(.*?)<\/ul>/gs, '<ul class="list-disc ml-6">$1</ul>')
      .replace(
        /<ol(.*?)>(.*?)<\/ol>/gs,
        '<ol class="list-decimal ml-6"$1>$2</ol>'
      ) // Adjust to include attributes
      .replace(/<li>(.*?)<\/li>/g, '<li class="mb-2">$1</li>')
      .replace(
        /<code>(.*?)<\/code>/g,
        '<code class="bg-slate-700 text-white px-1">$1</code>'
      )
      .replace(
        /<pre>(.*?)<\/pre>/g,
        '<pre class="bg-slate-700 text-white p-2 overflow-auto">$1</pre>'
      )
      .replace(/<img /g, '<img class="max-w-full h-auto rounded-lg shadow-lg" ')
      .replace(
        /<blockquote>(.*?)<\/blockquote>/g,
        '<blockquote class="bg-slate-700 text-white border-l-4 border-blue-800 p-2 mb-4 italic">$1</blockquote>'
      )
      .replace(
        /<a href="(.*?)">(.*?)<\/a>/g,
        '<a href="$1" class="text-blue-400 hover:underline">$2</a>'
      )
      .replace(
        /<button>(.*?)<\/button>/g,
        '<button class="bg-blue-800 text-white rounded px-4 py-2 hover:bg-blue-600">$1</button>'
      );

    return styledHtml;
  };

  if (loading)
    return (
      <div className="min-h-screen text-4xl bg-primary text-white text-center flex justify-center items-center">
        Loading...
      </div>
    );
  if (error)
    return (
      <div className="min-h-screen text-4xl bg-primary text-white text-center flex justify-center items-center">
        Error: {error}
      </div>
    );

  return (
    <div className="bg-primary min-h-screen w-full p-4 flex items-center justify-center mt-20">
      <main className="gap-8 bg-secondary p-4 rounded-xl w-full lg:w-[75%]">
        {post ? (
          <>
            <div>
              <div className="ml-2 md:ml-6 text-white mb-4">
                <h1 className="mt-4 text-white text-xl md:text-2xl font-bold">
                  {post.title}
                </h1>
                <div className="font-bold flex p-2 gap-2">
                  <img src={CategoryIcon} className="w-4" alt="category icon" />
                  {post.category}
                </div>
                <div className="font-bold flex p-2 gap-2">
                  <img src={TimeIcon} className="w-4" alt="time icon" />
                  {post.reading_time} min
                </div>
              </div>
              <img
                src={post.banner}
                className="w-full object-cover rounded"
                alt={"post banner"}
              />
            </div>
            <div className="m-2 md:m-6 text-white post-content">
              {/* Render HTML content directly */}
              <div
                dangerouslySetInnerHTML={{
                  __html: styledContent,
                }}
              ></div>
            </div>

            <div className="m-2 md:m-6 text-white">
              <span className="italic">Created On:</span>{" "}
              {new Date(post.date_posted).toLocaleDateString()} |
              <span className="italic"> Modified On:</span>{" "}
              {new Date(post.date_modified).toLocaleDateString()}
            </div>
          </>
        ) : (
          <div className="text-white">Post not found</div>
        )}
      </main>
    </div>
  );
};

export default SinglePost;
